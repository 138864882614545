import { userConstants, balanceConstants } from "../constants";
import { rateConstants } from "../constants";
import { userService, rateService } from "../services";
import { history } from "../helpers";

export const userActions = {
  authenticate,
  logout,
  updateUser,
  getRates,
  getBalance
};

function authenticate() {
  return dispatch => {
    dispatch(request());

    userService.authenticate().then(res => {
      if (res._id) {
        return dispatch(success(res));
      } else {
        dispatch(failure(res));
      }
    });
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success(user) {
    //console.log("RESULT", user)
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function updateUser(user) {
  userService.updateUser(user);
  return { type: userConstants.UPDATE_USER_DATA, user };
}

function getRates(currency) {
  return dispatch => {
    rateService.getRates(currency).then(res => {
      if (res) {
        dispatch(returnRates({ rate: res, crypto: currency }));
      }
    });
  };

  function returnRates(rates) {
    return { type: rateConstants.GET_RATES, rates };
  }
}

function getBalance(currency) {
  return dispatch => {
    userService.getBalance(currency).then(res => {
      dispatch(
        returnBalance({
          total_balance: res.total_balance,
          address_bal: res.address_bal
        })
      );
    });
  };

  function returnBalance(balance) {
    return { type: balanceConstants.GET_BALANCE, balance };
  }
}
