import all_countries from './all_countries.json';
import stripe_supported_countries from './stripe_supported_countries.json';
import flutter_supported_countries from './flutter_supported_countries.json';
import mobile_money_supported_countries from './mobile_money_supported_countries.json';
import mobile_money_supported_currencies from './mobile_money_supported_currencies.json';
import other_supported_countries from './other_supported_countries.json';
import stripe_currencies from './stripe_currencies.json';
import supported_currencies from './supported_currencies.json';
import phone_countries from './phone_countries.json';
import countries_with_regions from './countries_with_region.json';

import _ from 'lodash';
import { cloneElement } from 'react';

function getAllSupportedCountries(){
    const sc_full_Data = stripe_supported_countries.map( (c) => {
        return all_countries.filter( (c1) => c1.alpha2Code === c )
    })

    const oc_full_Data = other_supported_countries.map( (c) => {
        return all_countries.filter( (c1) => c1.alpha2Code === c )
    })
    return _.flatten(_.union(sc_full_Data, oc_full_Data));
}

function getAllSupportedCountriesPhone(){
    const countriesOfFocus = ["ng", "gh", "us", "ke", "ug", "cm", "gb", "ca"]
    const sc_full_Data = stripe_supported_countries.map( (c) => {
        return phone_countries.filter( (c1) => { 
            // console.log("Stripe Countries", c1);
            // console.log("Call", c);

            return c1.iso2 === c.toLowerCase() && countriesOfFocus.indexOf(c1.iso2) !== -1
        })
    })


    const oc_full_Data = other_supported_countries.map( (c) => {
        return phone_countries.filter( (c1) => c1.iso2 === c.toLowerCase() )
    })

    const all_full_data = _.flatten(_.union(oc_full_Data, sc_full_Data));

    return all_full_data.map( (c) => {
        return {
            "name": c.name,
            "iso2": c.iso2,
            "dialCode": c.dialCode,
            "priority": c.priority,
            "areaCodes": c.areaCodes
          }
    })
}

function getMobileMoneySupportedCountriesPhone(){
    const oc_full_Data = mobile_money_supported_countries.map( (c) => {
        return phone_countries.filter( (c1) => c1.iso2.toLowerCase() === c.toLowerCase() )
    })

    const all_full_data = _.flatten(oc_full_Data);

    return all_full_data.map( (c) => {
        return {
            "name": c.name,
            "iso2": c.iso2,
            "dialCode": c.dialCode,
            "priority": c.priority,
            "areaCodes": c.areaCodes
          }
    })
}

function isStripeSupportedCountry(country){
    const sc_full_Data = stripe_supported_countries.map( (c) => {
        return all_countries.filter( (c1) => c1.alpha2Code === c )
    })
    const arg = !_.isNull(country) && !_.isUndefined(country) ? country.toLowerCase() : '';

    const filtered = sc_full_Data.filter( (ct) => ct[0].alpha2Code.toLowerCase() === arg );

    if(!filtered || filtered.length < 1){
        return false
    } else {
        return true
    }
}

function isFlutterSupportedCountry(country){
    const oc_full_Data = flutter_supported_countries.map( (c) => {
        return all_countries.filter( (c1) => c1.alpha2Code === c )
    })
    const arg = !_.isNull(country) && !_.isUndefined(country) ? country.toLowerCase() : '';

    const filtered = oc_full_Data.filter( (ct) => ct[0].alpha2Code.toLowerCase() === arg );

    if(!filtered || filtered.length < 1){
        return false
    } else {
        return true
    }
}

function isMobileMoneySupportedCountry(country){
    const oc_full_Data = mobile_money_supported_countries.map( (c) => {
        return all_countries.filter( (c1) => c1.alpha2Code === c )
    })
    const arg = !_.isNull(country) && !_.isUndefined(country) ? country.toLowerCase() : '';

    const filtered = oc_full_Data.filter( (ct) => ct[0].alpha2Code.toLowerCase() === arg );

    if(!filtered || filtered.length < 1){
        return false
    } else {
        return true
    }
}

function isMobileMoneySupportedCurrency(currency){
    
    const filtered = mobile_money_supported_currencies.filter( (c) => c.toLowerCase() == currency.toLowerCase())
    
    if(!filtered || filtered.length < 1){
        return false
    } else {
        return true
    }
}


function isStripeSupportedCurrency(currency){
    const filtered = stripe_currencies.filter( (c) => c.toLowerCase() == currency.toLowerCase())
    
    if(!filtered || filtered.length < 1){
        return false
    } else {
        return true
    }
}

//
function getAllSupportedCurrenciesCountry(){
    const supported_countries = getAllSupportedCountries();
    const our_currencies_country = supported_currencies.map( cr => {
        return supported_countries.filter( (cr2) => { 
             if(cr2.currencies[0].code === cr){
                 return cr2.currencies[0].code === cr
             } 
             else if( cr2.currencies.length > 1 && (cr2.currencies[1].code === cr)){
                return cr2.currencies[1].code === cr
             }
        }); 
    })
    return _.flatten(our_currencies_country)
}


//pass in country code to return currency
function getCurrencyByCountry(country){
    const arg = !_.isNull(country) && !_.isUndefined(country) ? country.toUpperCase() : '';
    const currencies_country = getAllSupportedCurrenciesCountry();
    
    let currency =  currencies_country.filter( (ct2) => ct2.alpha2Code === arg);
    currency = currency.map((c) => {
        if(c.alpha2Code === 'SG'){
            return c.currencies[1].code 
        }
        return c.currencies[0].code 
    });

    return currency.length > 0 ? currency[0] : null
}

//pass in country code to return dial code
function getDialCodeByCountry(country){
    const arg = !_.isNull(country) && !_.isUndefined(country) ? country.toUpperCase() : '';
    const currencies_country = getAllSupportedCurrenciesCountry();
    let dialcode =  currencies_country.filter( (ct2) => ct2.alpha2Code === arg);

    dialcode = dialcode.map((c) => c.callingCodes[0]);

    return dialcode.length > 0 ? dialcode[0] : null
}

//pass in country Code to return country name
function getCountryNameByCode(code){
    const arg = !_.isNull(code) && !_.isUndefined(code) ? code.toUpperCase() : '';
    const currencies_country = getAllSupportedCurrenciesCountry();
    let country =  currencies_country.filter( (ct2) => ct2.alpha2Code === arg);

    country = country.map((c) => c.name);

    return country.length > 0 ? country[0] : null
}

//pass in currency to return country 
function getCountryByCurrency(){

}

// pass in country to get regions
function getRegionsByCountries(short_code) {
    const country_with_regions = countries_with_regions.filter(single_country => {
        if(single_country.countryShortCode === short_code.toUpperCase()) {
            return single_country;
        }
    });
    return country_with_regions[0].regions.length > 0 ? country_with_regions[0].regions : [];
}

export { 
    getAllSupportedCountriesPhone,
    getMobileMoneySupportedCountriesPhone, 
    getAllSupportedCountries, 
    getAllSupportedCurrenciesCountry,
    isStripeSupportedCountry,
    isStripeSupportedCurrency,
    getRegionsByCountries,
    isFlutterSupportedCountry,
    getCurrencyByCountry,
    getCountryNameByCode,
    isMobileMoneySupportedCountry,
    isMobileMoneySupportedCurrency,
    getDialCodeByCountry
};