import axios from "axios";

export const rateService = {
  getRates
};

function getRates(crypto) {
  const btc_usd_url = "https://api.coinbase.com/v2/prices/spot?currency=USD";
  const eth_usd_url = "https://api.cryptonator.com/api/ticker/eth-usd";

  const url = crypto === "BTC" ? btc_usd_url : eth_usd_url;

  return axios
    .get(url)
    .then(res => {
      if (res.status !== 200) {
        return null;
      }

      const price = Number(res.data.data.amount);

      return price;
    })
    .catch(err => {
      return null;
    });
}
