import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { users } from "./users.reducer";
import { rates } from "./rates.reducer";
import { balance } from "./balance.reducers";

const rootReducer = combineReducers({
  authentication,
  users,
  rates,
  balance
});

export default rootReducer;
