import feathers from "@feathersjs/client";
import io from "socket.io-client";
import host from "./config";

const currentServer = host;
// const socket = io(currentServer, {
//   transports: ["websocket"],
//   forceNew: true
// });

const feathersClient = feathers();
// feathersClient.configure(
//   feathers.socketio(socket, {
//     timeout: 30000
//   })
// );

feathersClient.configure(
  feathers.authentication({
    storage: window.localStorage,
    jwtStrategy: "jwt",
  })
);

export default feathersClient;
