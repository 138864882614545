let host: string;

switch (window.location.hostname) {
  case "localhost":
    host = "https://staging.afx-server.com";
    break;

  case "afriex-staging-web.herokuapp.com":
    host = "https://afriex-staging-server.herokuapp.com";
    break;

  default:
    host = "https://prod.afx-server.com";
}

console.log("config host =>", host);

export default host;
