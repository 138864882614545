import React, { Component } from "react";
import feathersClient from "../../feathers-client";
import "./error-handling.css";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      errorEmail: ""
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
      errorEmail: errorInfo
    });
    // Catch errors in any components below and re-render with error message

    feathersClient
      .service("/email")
      .create({
        from: "Peppement Team <admin@peppement.com>",
        to: "obirijejohn@gmail.com,alabitemitope@gmail.com",

        subject: "error",
        html: " " + errorInfo.componentStack + " "
      })
      .then(res => {
        // console.log("res ======0---->", res)
      })
      .catch(error => {
        if (error.code === 11000) {
          // console.log("error------>error")
        }
      });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <br />
          <br />
          <br />
          <br />
          <div className="dev-error-handing">
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
            <hr />
            <h2>Something went wrong.</h2>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
