import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../actions";
import currencyFormatter from "currency-formatter";

class NavItems extends Component {
	constructor(props) {
		super(props);
	}

	logout = () => {
		const { dispatch } = this.props;

		dispatch(userActions.logout());
	};

	componentDidMount() {}

	render() {
		const { loggedIn, ngnRate, cryptoRate } = this.props;

		let BTC_NGN = currencyFormatter.format(ngnRate * cryptoRate, {
			code: "NGN",
		});

		return (
			<div className="navbar-menu">
				<div className="navbar-end">
					{loggedIn === false ? (
						<div className="navbar-item">
							{/* <div
                className="is-hidden-mobile"
                style={{ marginRight: "4%", marginLeft: "-10%" }}
              >
                {BTC_NGN ? "1 BTC = " + BTC_NGN : ""}
              </div> */}

							{/* <Link
                to="register"
                className="button new-nav-btn"
              >
                <span> Sign Up </span>
              </Link> */}
						</div>
					) : (
						<div className="navbar-end">
							<div className="navbar-item">
								<a className="navbar-item" onClick={this.logout}>
									<span className="has-text-light material-icons">
										power_settings_new
									</span>
									<span
										className="has-text-light"
										style={{ display: "inline-block", marginLeft: "10px" }}
									>
										Sign Out
									</span>
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { authentication, rates } = state;
	const { loggedIn } = authentication;
	const { ngnRate, cryptoRate } = rates;
	return {
		loggedIn,
		ngnRate,
		cryptoRate,
	};
}

export default connect(mapStateToProps)(NavItems);
