import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { parseFullName } from "parse-full-name";

export const deriveUserName = (email: string): string => {
  let userName = email.substring(0, email.lastIndexOf("@"));
  userName = userName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  userName = userName.length > 5 ? userName.substring(0, 5) : userName;
  let randomInt = Math.floor(Math.random() * 10 ** 3).toString();
  return userName + randomInt.toString();
};

export const parseName = (name: string): any => {
  if (!name) {
    return {};
  }
  const nameParts = parseFullName(name);

  return {
    firstName: nameParts?.first?.trim(),
    lastName: nameParts?.last?.trim(),
  };
};

export const validatePhoneNumber = (
  phone: string,
  countryCode: CountryCode
) => {
  const phoneNumber = parsePhoneNumber(phone, countryCode);
  return (
    phoneNumber.isValid() &&
    phoneNumber?.country?.toLowerCase() ===
      countryCode.toString()?.toLowerCase()
  );
};

export const validateEmail = (email: string): boolean => {
  //eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return regex.test(password);
};

export const validate = ({
  email,
  password,
  name,
  phone,
}: any): {
  [key: string]: boolean;
} => {
  // true means invalid, so our conditions got reversed
  // console.log(!Number(phone), phone.length)
  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  return {
    email: email?.length === 0 || email?.length < 7,
    password: !regex.test(password),
    phone: phone?.length < 6,
    name: name?.length === 0 || name?.length < 3,
  };
};

export const InputSuccessOrError = (props: any) => {
  const { shouldMarkError, message, ...rest } = props;

  if (shouldMarkError) {
    return (
      <div {...rest} className="validation-info error cust-validation-error">
        <span>{message}</span>
      </div>
    );
  } else {
    return <span />;
  }
};
