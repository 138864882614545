import feathersClient from "../feathers-client";

export const userService = {
  authenticate,
  logout,
  updateUser,
  getBalance
};

function authenticate() {
  return feathersClient.passport
    .getJWT()
    .then(token => {
      return feathersClient.authenticate({
        strategy: "jwt",
        accessToken: token
      });
    })
    .then(response => {
      return feathersClient.passport.verifyJWT(response.accessToken);
    })
    .then(payload => {
      return feathersClient.service("users").get(payload.userId);
    })
    .then(user => {
      feathersClient.set("user", user);
      return user;
    })
    .catch(error => {
      //console.log("error no auth or something", error);

      if (error.code === 401) {
        logout();
      }

      return error;
    });
}

function logout() {
  // remove user from local storage to log user out
  feathersClient.logout();
}

function updateUser(user) {
  feathersClient.set("user", user);
}

function getBalance(currency) {
  const params = {
    query: {
      crypto: currency
    }
  };

  return feathersClient
    .service("/wallet-balance")
    .find(params)
    .then(result => {
      //console.log("result from wallet balance", result);

      return {
        total_balance: result.total_balance / 100000000,
        address_bal: result.address_bal
      };
    })
    .catch(err => {
      return { total_balance: 0, address_bal: [] };
    });
}
