//importing all components
import React, { useState } from "react";
import ReactGA from "react-ga";
import "./assets/css/app.css";
import Register from "./components/register";
import HttpsRedirect from "react-https-redirect";
import ErrorBoundary from "./components/error-handling/error-handling";
import Page404 from "./components/page/page-404";

import { Route, BrowserRouter, Switch } from "react-router-dom";
import LandingPage from "./components/landing-page";
import Recover from "./components/recover-password";

//Start Google analytics
const trackingId = "UA-125085111-1"; //
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
// End google analytics

const App = () => {
  const [noErrorPage, setNoErrorPage] = useState(false);

  const noerorrpage = (data: any) => {
    if (data) {
      setNoErrorPage(true);
    } else {
      setNoErrorPage(false);
    }
  };

  return (
    <HttpsRedirect>
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => (
              <ErrorBoundary>
                {" "}
                <LandingPage noerorrpage={noerorrpage} />{" "}
              </ErrorBoundary>
            )}
          />

          <Route
            path="/register/:username"
            exact
            render={(props) => (
              <Register {...props} noerorrpage={noerorrpage} />
            )}
          />

          <Route
            path="/login"
            exact
            render={(props) => <Recover {...props} noerorrpage={noerorrpage} />}
          />

          {!noErrorPage ? (
            <Route exact path="*" component={Page404} />
          ) : (
            <div />
          )}
        </Switch>
      </BrowserRouter>
    </HttpsRedirect>
  );
};

export default App;
