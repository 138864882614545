import { rateConstants } from "../constants";

export function rates(state = {}, action) {
  switch (action.type) {
    case rateConstants.GET_RATES:
      return {
        cryptoRate: action.rates.rate,
        crypto: action.rates.crypto,
        ngnRate: 363
      };
    default:
      return state;
  }
}
