import { balanceConstants } from "../constants";

export function balance(state = {}, action) {
  switch (action.type) {
    case balanceConstants.GET_BALANCE:
      return {
        total_balance: action.balance.total_balance,
        address_bal: action.balance.address_bal
      };
    default:
      return state;
  }
}
